class StickyAtcCard extends HTMLElement {
  constructor () {
    super()

    this.variantJSON = document.querySelector('[data-variant-json]')
    if ( this.variantJSON ) {
      this.variant = JSON.parse(this.variantJSON.innerText)
      this.chipButtons = document.querySelectorAll('.js-button-chip')
      this.cloneChipButtons = this.querySelectorAll('.js-button-clone-chip')
      this.chipButtonEls = document.querySelectorAll('.js-button-chip')
      this.stickyAtcCardPrice = document.querySelector('.js-sticky-atc-card-price')
      this.stickyAtcCardComparePrice = document.querySelector('.js-sticky-atc-card-compare-price')
      this.isRecharge = this.getAttribute('data-recharge')

      this.changeData()
      this.cloneChipButtons.length === 0 && this.chipButtonEls && this.chipButtonEls.forEach(button => {
        this.setActiveVariant(button)
      })
    }

    const isShow = 'is-show-sticky-card'
    const addToCart = document.querySelector('.js-pdp-add-to-cart')
    const headerEl = document.querySelector('.js-site-header')
    const headerHeight = headerEl ? headerEl.offsetHeight : 0

    if (addToCart) {
      window.addEventListener('scroll', () => {
        const footerEl = document.querySelector('.js-footer')
        const positionATC = addToCart.getBoundingClientRect().bottom
        const footerIsInViewport = footerEl?.getBoundingClientRect().top - footerEl?.offsetHeight < 0
        if (positionATC <= parseFloat(headerHeight) && !footerIsInViewport) {
          document.body.classList.add(isShow)
        } else {
          document.body.classList.remove(isShow)
        }
      }, { passive: true })
    }
  }

  setActiveVariant(button) {
    button.addEventListener('click', () => {
      this.changeData()
    })
  }

  changeData () {
    if (this.isRecharge == 'false') {
      const priceWrapper = document.querySelector('.js-product-price')
      if (priceWrapper) {
        const comparePrice = priceWrapper.querySelector('[data-product-compare-price]')
        const price = priceWrapper.querySelector('[data-price]')
        this.changePrice(comparePrice.innerText, price.innerText)
      }
    }
  }

  changePrice (comparePrice, price) {
    if (this.stickyAtcCardPrice && price != '') this.stickyAtcCardPrice.innerText = price
    if (this.stickyAtcCardComparePrice && comparePrice != '') this.stickyAtcCardComparePrice.innerText = comparePrice
  }
}
window.customElements.define('sticky-card', StickyAtcCard)
